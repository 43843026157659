import React, { useEffect } from "react";
import AOS from "aos";
import Video from "./Video";
import "./Tab.css";
import "./Card.css";
import "aos/dist/aos.css";
import Footer from "./Footer/Footer";
import Navbar from "./Nabvar/Navbar";
import HomeContact from "./HomeContact";
import AccaAgkika from "./AccaAgkika";
import ServicesSlider from "./ServicesSlider";
import Tab from "./Tab";
const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Navbar />

      <Video />

      <div className="icon-bar">
        <a
          href="https://wa.me/44-7880-321-009"
          className="whatsapp"
          target="_blank"
        >
          <i className="fab fa-whatsapp"></i>
          &nbsp;How Can I help you ?
        </a>
      </div>

      {/*Why Us Section*/}
      <section className="featured-section-two pt-5">
        <div className="auto-container">
          <div className="row clearfix">
            {/*Image Column*/}
            <div className="image-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner">
                <figure className="image wow fadeInLeft">
                  <img
                    className="tilt-item"
                    src="assets\images\background\abts.jpeg"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            {/*Text Column*/}
            <div className="text-column col-lg-7 col-md-12 col-sm-12">
              <div class="sec-title-two">
                <div class="sub-title sub-titles">about us </div>
                <h2
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  class="aos-init aos-animate"
                  style={{ color: "#234a96" }}
                >
                  Founded in 2023
                </h2>
                <div class="lower-text">
                  At Agkika, we deliver more than just accounting services; we
                  provide divine financial wisdom that elevates your firm above
                  the seasonal frenzy of tax deadlines. <br />
                  Choose <b>Agkika,</b> we ensure you get <b>straight A’s</b> in
                  Accounting and Accountability <br />
                  Let us take care of the details, so you can focus on what
                  truly matters—your clients and their success.
                </div>
              </div>
              <div className="content">
                <ul>
                  <li>
                    <b className="text-dark">A</b>ccurate records - We handle
                    every transaction with accuracy to ensure “pristine” records
                    that are fully compliant
                  </li>
                  <li>
                    <b className="text-dark">A</b>mple scalability - Whether
                    you’re a startup or a well-established firm, we can scale up
                    to meet your evolving needs.
                  </li>
                  <li>
                    <b className="text-dark">A</b>ctionable insights - We equip
                    you with handy insights and strategic counsel to navigate
                    complex financial landscapes effectively.
                  </li>
                  <li>
                    <b className="text-dark">A</b>daptable approach - We adapt
                    our services to align perfectly with your and your clients'
                    specific needs, enhancing both satisfaction and retention.
                  </li>
                  <li>
                    <b className="text-dark">A</b>rticulate audits - With every
                    document well organized, face every audit with confidence
                    and your business ready for scrutiny.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Services Section*/}
      {/* <ServicesSlider /> */}
      {/*Team Section*/}

      {/* Jagriti Singh */}
      <section className="news-section pt-5 pb-5">
        <div className="faded-text style-two"></div>
        <div className="auto-container">
          <div className="bubble-dotted">
            <span className="dotted dotted-1" />
            <span className="dotted dotted-2" />
            <span className="dotted dotted-3" />
            <span className="dotted dotted-4" />
            <span className="dotted dotted-5" />
            <span className="dotted dotted-6" />
            <span className="dotted dotted-7" />
            <span className="dotted dotted-8" />
            <span className="dotted dotted-9" />
            <span className="dotted dotted-10" />
            <span className="dotted dotted-11" />
            <span className="dotted dotted-12" />
            <span className="dotted dotted-13" />
          </div>
          <div className="sec-title-three h2">
            <div className="sec-title centered">
              <div className="sub-title" style={{ color: "#147fd4" }}>
                services
              </div>
              <h2 style={{ color: "#234a96" }} data-aos="flip-left">
                Our Founders
              </h2>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-md-6">
              <div className="wrapper" data-aos="flip-left">
                <div className="user-card">
                  <div className="user-card-img">
                    <img src="assets\images\man png circle.png" alt="" />
                  </div>
                  <div className="user-card-info">
                    <h3 className="text-start">Harsh Agarwal</h3>
                    <p style={{ textAlign: "justify" }}>
                      I embarked on a journey to redefine accountancy. Armed
                      with experience and a commitment to excellence, Agkika was
                      born. Beyond the balance sheets, I find joy in exploring
                      new cultures and cuisines, fueling my adventurous spirit.
                      Join me as we navigate the intricate world of finance,
                      where every number tells a story and every adventure
                      brings new possibilities. Welcome to our journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper" data-aos="flip-right">
                <div className="user-card">
                  <div className="user-card-img">
                    <img src="assets\images\giris.jpeg" alt="" />
                  </div>
                  <div className="user-card-info">
                    <h3 className="text-start">Girish Shekar</h3>
                    <p style={{ textAlign: "justify" }}>
                      By day, a seasoned accounting professional with 12 years
                      of experience in all things debits and credits (financial
                      reporting, taxation, auditing, you name it). But don't let
                      the suit fool you - I'm also a derivatives trader with a
                      knack for portfolio management (by day, or maybe by night)
                      and a passionate startup enthusiast with 8 years of
                      experience advising new ventures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Tab />

      <AccaAgkika />

      {/*Featured Section*/}
      {/* <section className="featured-section-two pt-5 pb-5">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner ani">
                <figure className="image wow fadeInLeft">
                  <img
                    className="tilt-item"
                    src="assets/images/resource/featured-image-7.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title-two">
                  <div className="sub-title sub-titles">AGKIKA</div>
                  <h2
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    style={{ color: "#234a96" }}
                  >
                    WHY CHOOSE US
                  </h2>
                  <div className="lower-text">
                    We invite you to experience our infectious energy and
                    unwavering pursuit of excellence.
                  </div>
                </div>
                <div className="content">
                  <ul>
                    <li>We are Agkika - the accountants of gods</li>
                    <li>We dare say, work is worship for us.</li>
                    <li>
                      We serve the divine through you and our offering is our
                      work
                    </li>
                    <li>
                      Have a look at everything about us, from the site to the
                      presentations of the same information that innumerable our
                      our competitors provide on their site, It is a testament
                      to our creativity and commitment and passion towards our
                      work.
                    </li>
                    <li>
                      If still not convinced, give us a call, we will infect you
                      with our energy, passion and strive for excellence and you
                      will be convinced, but beware, you will never be able to
                      settle for mediocrity.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <HomeContact />

      <Footer />
    </>
  );
};

export default Home;

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css';

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_md54fts', 'template_eb2gjun', form.current, {
        publicKey: 'qDoZp6n8KbWlRRK-E',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <>
      {/* <form ref={form} onSubmit={sendEmail} className='validate' method="post" action="/" id="form">
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form> */}

      <div class="form-box">
        <form ref={form} onSubmit={sendEmail} className='validate' method="post" action="/">
          <div class="form-group">
            <label for="name">Name</label>
            <input class="form-control" id="name" type="text" name="user_name" />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input class="form-control" type="email" name="user_email" />
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea class="form-control" id="message" name="message"></textarea>
          </div>
          <input class="btn btn-primary" type="submit" value="Send" />
        </form>
      </div>
      </>
      )
}

      export default ContactUs
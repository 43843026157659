import Typewritter from "typewriter-effect";

const texts = [
    "Our expert team manages your finances efficiently, so you can manage your future effectively",
    "Our compliance management ensures you stay ahead, hassle-free and audit-ready.",
    "Take advantage of our sage advice and divine expertise to reduce tax burdens legally and efficiently, ensuring serenity in compliance.",
    "Our personalized business modeling provides the blueprint for your future achievements and today’s actions.",
    "Our treasury management services ensure liquidity is optimized, powering up every aspect of your operations."
  ];


const TypewriterEffect = () => {
    <Typewritter
    onInit={(typewriter) => {
      typewriter
        .typeString(
          "We Crunch Your Numbers So That You Crush the Competition "
        )

        .deleteAll()
        .typeString(
          "Streamlining your Complexity of Numbers, We Simplify Success "
        )
        .deleteAll()
        .typeString(
          "Minimize your Tax, Maximize your Peace "
        )
        .deleteAll()
        .typeString(
          "We Develop Business Model for Tomorrow and Set the Milestone Today "
        )
        .deleteAll()
        .typeString(
          "Cultivating your Cash Flow Helps your Business Grow… "
        )
        .deleteAll()
        .typeString(
          "Invest with Agkika Intelligence with our Sage Financial Foresight"
        )
        .deleteAll()
        .start();
    }}
    loop={true}
    deleteSpeed={50}
    cursor={true}
    cursorStyle="_"
    typeSpeed={100}
    />
}

export default TypewriterEffect;

import React from 'react'

const Footer = () => {

    const handleBackToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {/* Main Footer */}
            <footer className="main-footer">
                <div className="auto-container">
                    {/*Widgets Section*/}
                    <div className="widgets-section">
                        <div className="row clearfix">
                            {/*Column*/}
                            <div className="column col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget about-widget">
                                    <div className="widget-content">
                                        <div className="footer-logo">
                                            <a href="/">
                                                <img src="assets\images\icons\ag-icon.png" alt="" style={{ width: 140 }} />
                                            </a>
                                        </div>
                                        <p>
                                            Founded in 2023,Agkika is disrupting traditional outsoucing throught innovation and collaboration.
                                        </p>
                                        <p>
                                            Our small but dedicated team is commited to setting new standards in client delevery.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            {/*Column*/}
                            <div className="column col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget links-widget">
                                    <h3>
                                        UseFul Link <span>.</span>
                                    </h3>
                                    <div className="widget-content">
                                        <div className="row clearfix">

                                            <div className="col-lg-12 col-md-6 col-sm-12">
                                                <ul>
                                                    <li>
                                                        <a href="/">Home</a>
                                                    </li>
                                                    <li>
                                                        <a href="/about">About</a>
                                                    </li>
                                                    <li>
                                                        <a href="/service">Services</a>
                                                    </li>
                                                    <li>
                                                        <a href="/blog">Blog</a>
                                                    </li>
                                                    <li>
                                                        <a href="/contact">Contact</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Column*/}
                            <div className="column col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget news-widget">
                                    <h3>
                                        Contact Us <span>.</span>
                                    </h3>
                                    <div className="widget-content">
                                        <div className="row clearfix">
                                            <div className="col-lg-12 col-md-6 col-sm-12">
                                                <ul>
                                                    <li>
                                                        <i class="fas fa-home me-3"></i> <a href="#">124-128 City Road, EC1V 2NX, London, England GB</a>
                                                    </li>
                                                    <li>
                                                        <i class="fas fa-envelope me-3"></i><a href="#"> info@agkika.com</a>
                                                    </li>
                                                    <li>
                                                        <i class="fas fa-phone me-3"></i><a href="#">+ + 44-7776-681468</a>
                                                    </li>
                                                    <li>
                                                        <i class="fas fa-globe me-3"></i><a href="#">+ www.agkika.co.uk</a>
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}

            </footer>
            <div
                className="scroll-to-top scroll-to-target" onClick={handleBackToTop}
                data-target="html"
                style={{ display: "block" }}
            >
                <span className="flaticon-up-arrow" />
            </div>
        </>
    )
}

export default Footer
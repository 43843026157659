import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const AccaAgkika = () => {
    const options = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: false,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 2,
                nav: false
            },
            1000: {
                items: 3,
                nav: true,
                loop: false
            }
        }
    };
    return (
        <>
            {/*Services Section*/}
            <section className="services-section-three pt-5" style={{ background: "#07095b" }}>
                <div className="sec-title-three h2 pt-3">
                    <div className="sec-title style-three light centered" style={{ margin: "0px auto 10px" }}>
                        <span className='' style={{ color: "#e6d673" }} data-aos="flip-left">Reasons why every ACCA firm in UK should choose Agkika</span>
                    </div>
                    <div className="lower-text text-center text-light">
                        Step into a partnership with Agkika, where seasoned wisdom meets financial expertise, transforming your business approach. <br /> We literally work as <b>“accounting sages full of financial wisdom.”</b> That’s the first reason. Know the other reasons here:
                    </div>
                </div>
                <div className="outer-container clearfix">
                    <div className="row clearfix">
                        {/*Block*/}
                        <div className="service-block-three col-xl-6 col-lg-12 col-md-12">
                            <div className="inner-box">
                                <div className="row clearfix">
                                    {/*Text Column*/}
                                    <div className="text-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon">
                                                <span className="flaticon-worldwide-1" style={{ filter: "drop-shadow(2px 4px 6px black)" }} />
                                            </div>
                                            <div className="count">01</div>
                                            <div className="text">
                                                <span className='fw-bold text-light'>Heavenly Precision in Details</span> Count on us to maintain your books with divine accuracy, ensuring every number aligns perfectly, supported by meticulous audits and consistent checks.
                                                <br />
                                                <span className='fw-bold text-light'>Your Offsite Team, Just a Call Away</span> We act not merely as an offshore provider, but as an extension of your own team. Always ready to assist, seamlessly integrated into your operations.
                                                <br />
                                                <span className='fw-bold text-light'>Customized Solutions for Every Need</span> At Agkika, we understand that every business is unique. We tailor our services to perfectly match your specific requirements, ensuring optimal outcomes.
                                            </div>
                                        </div>
                                    </div>
                                    {/*Image Column*/}
                                    <div className="image-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div
                                                className="image-layer"
                                                style={{ backgroundImage: "url(https://img.freepik.com/free-photo/full-body-picture-smiling-business-woman-presenting-something-back-with-hand-holding-marker-isolated-white-wall_231208-8761.jpg?t=st=1715078528~exp=1715082128~hmac=9935925b15966ac5f8c818105764869c4f3280e76263b44d875cc810830ddace&w=360)" }}
                                            />
                                            <figure className="image">
                                                <img src="https://img.freepik.com/free-photo/full-body-picture-smiling-business-woman-presenting-something-back-with-hand-holding-marker-isolated-white-wall_231208-8761.jpg?t=st=1715078528~exp=1715082128~hmac=9935925b15966ac5f8c818105764869c4f3280e76263b44d875cc810830ddace&w=360" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Block*/}
                        <div className="service-block-three col-xl-6 col-lg-12 col-md-12">
                            <div className="inner-box">
                                <div className="row clearfix">
                                    {/*Text Column*/}
                                    <div className="text-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon">
                                                <span className="flaticon-paint-brush-1" style={{ filter: "drop-shadow(2px 4px 6px black)" }}/>
                                            </div>
                                            <div className="count">02</div>
                                            <div className="text">
                                                <span className='fw-bold text-light'>Guaranteed Satisfaction</span> Trust our processes, and in just six months, you'll see why our clients rarely need to review our work. A brief check-in is all you’ll need to confirm everything is on track. <br />
                                                <span className='fw-bold text-light'>Based in London, Available Globally</span> Your main point of contact will always be a qualified CA based in London, ready to meet in person whenever necessary.
                                                <br />
                                                <span className='fw-bold text-light'>More Than Cost Savings</span> Our focus extends beyond simple cost reduction; we aim to add significant value to every aspect of your business operations.
                                            </div>
                                        </div>
                                    </div>
                                    {/*Image Column*/}
                                    <div className="image-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div
                                                className="image-layer"
                                                style={{ backgroundImage: "url(https://img.freepik.com/free-photo/hand-near-laptop-that-expels-graphics-arrows_1232-283.jpg?t=st=1715074468~exp=1715078068~hmac=be34397c24b8f436e385554c43d821502979d60b9c9a6a456367474b8cdbb72b&w=740)" }}
                                            />
                                            <figure className="image">
                                                <img src="https://img.freepik.com/free-photo/hand-near-laptop-that-expels-graphics-arrows_1232-283.jpg?t=st=1715074468~exp=1715078068~hmac=be34397c24b8f436e385554c43d821502979d60b9c9a6a456367474b8cdbb72b&w=740" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Block*/}
                        <div className="service-block-three alternate col-xl-6 col-lg-12 col-md-12">
                            <div className="inner-box">
                                <div className="row clearfix">
                                    {/*Image Column*/}
                                    <div className="image-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div
                                                className="image-layer"
                                                style={{ backgroundImage: "url(https://img.freepik.com/free-photo/portrait-satisfied-happy-businessman-eyeglasses_171337-9569.jpg?t=st=1715076359~exp=1715079959~hmac=1eedfd8282e408e5341e550523d01bfbc1d1e933dcfd99e74af63930c7e5ef68&w=826)" }}
                                            />
                                            <figure className="image">
                                                <img src="https://img.freepik.com/free-photo/portrait-satisfied-happy-businessman-eyeglasses_171337-9569.jpg?t=st=1715076359~exp=1715079959~hmac=1eedfd8282e408e5341e550523d01bfbc1d1e933dcfd99e74af63930c7e5ef68&w=826" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    {/*Text Column*/}
                                    <div className="text-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon">
                                                <span className="flaticon-vector" style={{ filter: "drop-shadow(2px 4px 6px black)" }}/>
                                            </div>
                                            <div className="count">03</div>
                                            <div className="text">
                                                <span className='fw-bold text-light'>Proactive Updates and Insights</span> Our intelligent delivery model stays ahead of changes in taxation, accounting, and compliance, providing you with timely updates and actionable insights.
                                                <br />
                                                <span className='fw-bold text-light'>Innovative Solutions to Traditional Problems</span> We challenge the status quo with creative solutions that address conventional industry challenges, enhancing your competitive edge.
                                                <br />
                                                <span className='fw-bold text-light'>Customized Services for Every Realm</span> Our services are tailored to each client's unique needs, ensuring personalized solutions that range from tax planning to comprehensive compliance management..
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Block*/}
                        <div className="service-block-three alternate col-xl-6 col-lg-12 col-md-12">
                            <div className="inner-box">
                                <div className="row clearfix">
                                    {/*Image Column*/}
                                    <div className="image-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div
                                                className="image-layer"
                                                style={{ backgroundImage: "url(https://img.freepik.com/free-photo/businessman-his-office-holding-apple_1368-4628.jpg?t=st=1715074988~exp=1715078588~hmac=ee8525dc294ab46b2df45eb7a0d3eba63e9cb3bbdcc8d2580c3ba31b2b76039d&w=826)" }}
                                            />
                                            <figure className="image">
                                                <img src="https://img.freepik.com/free-photo/businessman-his-office-holding-apple_1368-4628.jpg?t=st=1715074988~exp=1715078588~hmac=ee8525dc294ab46b2df45eb7a0d3eba63e9cb3bbdcc8d2580c3ba31b2b76039d&w=826" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    {/*Text Column*/}
                                    <div className="text-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon">
                                                <span className="flaticon-user-3" style={{ filter: "drop-shadow(2px 4px 6px black)" }}/>
                                            </div>
                                            <div className="count">04</div>
                                            <div className="text">
                                                <ul>
                                                    <li><span className='fw-bold text-light bult'>Guardians of Compliance</span> Acting as your compliance shield, we keep you protected from regulatory dragons, staying proactive with the latest laws and standards.</li>
                                                    <li><span className='fw-bold text-light'>Proactive Communication with Celestial Beings</span> We maintain clear and continuous dialogue with tax authorities and regulatory bodies, ensuring you remain in divine favor and avoid fiscal penalties.</li>
                                                    <li><span className='fw-bold text-light'>Eternal Commitment to Service Excellence:</span> Our commitment to your financial health is unwavering, characterized by an endless pursuit of excellence and a dedication to delivering outstanding service.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AccaAgkika
import React, { useState, useEffect } from "react"; // Import useState and useEffect
import "./Video.css";
import Typewritter from "typewriter-effect";
import TypewriterEffect from "../utils/TypewriterEffect";

const Video = () => {
  return (
    <>
      <section id="hero-bg-video banner-section">
        <div className="video-container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark-overlay" />
              {/* <video autoPlay loop muted playsInline>
            <source
              src="assets\images\vdo.mp4"
              type="video/mp4"
            />
          </video> */}
              <img src="assets\images\hg.jpg" alt="" />
              {/* <img class="d-block w-100" src="https://img.freepik.com/free-vector/gradient-network-connection-background_23-2148865392.jpg?w=826&t=st=1712667807~exp=1712668407~hmac=5b6c05980dc860566f857cb7b6ece2ae502f5c030547d27964210eb5b6becfa0" alt="First slide" /> */}
              <div className="overlay-desc">
                <div className="overlay-desc--wrap">
                  <div className="auto-container">
                    <div className="content-box">
                      {/* <h1 className='text-light text-center'>TRANSFORMING</h1> */}
                      <div className="typewriter">
                        <h1 className="text-light text-center">
                          <Typewritter
                          options={{
                            deleteSpeed: 1000,
                            cursorStyle: "_",
                            typeSpeed: 1000,
                            loop: true,
                           
                            
                          }}
                            onInit={(typewriter) => {
                              typewriter
                                .typeString(
                                  "We Crunch Your Numbers So That You Crush the Competition "
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .typeString(
                                  "Streamlining your Complexity of Numbers, We Simplify Success "
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .typeString(
                                  "Minimize your Tax, Maximize your Peace "
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .typeString(
                                  "We Develop Business Model for Tomorrow and Set the Milestone Today "
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .typeString(
                                  "Cultivating your Cash Flow Helps your Business Grow… "
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .typeString(
                                  "Invest with Agkika Intelligence with our Sage Financial Foresight"
                                )
                                .pauseFor(5000)
                                .deleteAll()
                                .start();
                            }}
                            
                          />
                        </h1>
                      </div>
                      <div className="content clearfix text-center">
                        <div className="inner">
                          <div className="sub-title">
                            <h3>
                              <Typewritter
                              options={{
                                loop: true,
                                deleteSpeed: 2000,
                                cursorStyle: "_",
                                typeSpeed: 2000,
                                
                              }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .typeString(
                                      "Our compliance management ensures you stay ahead, hassle-free and audit-ready. "
                                    )
                                    .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "Our compliance management ensures you stay ahead, hassle-free and audit-ready. "
                                    )
                                     .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "Take advantage of our sage advice and divine expertise to reduce tax burdens legally and efficiently, ensuring serenity in compliance. "
                                    )
                                     .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "Our personalized business modeling provides the blueprint for your future achievements and today’s actions. "
                                    )
                                     .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "Our treasury management services ensure liquidity is optimized, powering up every aspect of your operations. "
                                    )
                                     .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "Our treasury management services ensure liquidity is optimized, powering up every aspect of your operations. "
                                    )
                                    
                                     .pauseFor()
                                    .deleteAll()
                                    .typeString(
                                      "We enhance investment returns through smart analysis, anticipating shifts and securing advantages. "
                                    )
                                    
                                    .deleteAll()

                                    .start();
                                }}
                                loop={true}
                                delay={1000}
                              />
                            </h3>
                            {/* <TypewriterEffect texts={texts}/> */}
                          </div>

                          {/* <div className="link-box clearfix">
      <a href="contact.html" className="theme-btn btn-style-nine">
        <span className="btn-title">Get A Quote</span>
      </a>
      <a href="/about" className="theme-btn btn-style-five">
        <span className="btn-title">Learn More</span>
      </a>
    </div> */}
                        </div>
                      </div>

                      <div className="scroll-down">
                        <div
                          className="scroll-to-target"
                          data-target="#scroll-section"
                        >
                          {/* <span className="icon flaticon-right-arrow" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Video;

import React, { useState } from 'react'
import Navbar from './Nabvar/Navbar'
import Footer from './Footer/Footer'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Service = () => {

  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };


  return (
    <>
      <Navbar />
      {/* Banner Section */}
      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/banner-image-1.jpg)" }}
        />
        <div className="banner-inner">
          <div className="faded-text light">
            <div className="f-text">
              <span>services</span>
            </div>
          </div>
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>our services</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/l">Home</a>
                  </li>
                  <li className="active">Our services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
            
      {/*Services Section*/}
      <section className="services-section-four">
        {/* <div className="bubble-dotted">
          <span className="dotted dotted-1" />
          <span className="dotted dotted-2" />
          <span className="dotted dotted-3" />
          <span className="dotted dotted-4" />
          <span className="dotted dotted-5" />
          <span className="dotted dotted-6" />
          <span className="dotted dotted-7" />
          <span className="dotted dotted-8" />
          <span className="dotted dotted-9" />
          <span className="dotted dotted-10" />
        </div> */}
        <div className="faded-text">
          <div className="f-text">
            <span>services</span>
          </div>
        </div>
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="sub-title">services</div>
            <h2>What We Do</h2>
          </div>
          <div className="tabs-box services-tabs">
            <div className="tab-buttons">
              <div className="row clearfix">
                {/* <OwlCarousel className="owl-theme" {...options}> */}
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-1' ? 'active-btn' : ''}`}
                      data-tab="#tab-1"
                      onClick={() => handleTabClick('tab-1')}
                    >
                      <span className="icon-bg pe-7s-target" />
                      <span className="icon pe-7s-target" />
                      <span className="txt">Accounting and Compliance</span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-2' ? 'active-btn' : ''}`}
                      data-tab="#tab-2"
                      onClick={() => handleTabClick('tab-2')}
                    >
                      <span className="icon-bg pe-7s-star" />
                      <span className="icon pe-7s-star" />
                      <span className="txt">Payroll Services </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-3' ? 'active-btn' : ''}`}
                      data-tab="#tab-3"
                      onClick={() => handleTabClick('tab-3')}
                    >
                      <span className="icon-bg pe-7s-science" />
                      <span className="icon pe-7s-science" />
                      <span className="txt">Accounting Services </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-4' ? 'active-btn' : ''}`}
                      data-tab="#tab-4"
                      onClick={() => handleTabClick('tab-4')}
                    >
                      <span className="icon-bg pe-7s-disk" />
                      <span className="icon pe-7s-disk" />
                      <span className="txt">Tax Services </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-5' ? 'active-btn' : ''}`}
                      data-tab="#tab-5"
                      onClick={() => handleTabClick('tab-5')}
                    >
                      <span className="icon-bg pe-7s-joy" />
                      <span className="icon pe-7s-joy" />
                      <span className="txt">Audit Support</span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-6' ? 'active-btn' : ''}`}
                      data-tab="#tab-6"
                      onClick={() => handleTabClick('tab-6')}
                    >
                      <span className="icon-bg pe-7s-portfolio" />
                      <span className="icon pe-7s-portfolio" />
                      <span className="txt">Advisory and Consulting</span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-7' ? 'active-btn' : ''}`}
                      data-tab="#tab-7"
                      onClick={() => handleTabClick('tab-7')}
                    >
                      <span className="icon-bg pe-7s-target" />
                      <span className="icon pe-7s-target" />
                      <span className="txt">Budgeting </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-8' ? 'active-btn' : ''}`}
                      data-tab="#tab-8"
                      onClick={() => handleTabClick('tab-8')}
                    >
                      <span className="icon-bg pe-7s-star" />
                      <span className="icon pe-7s-star" />
                      <span className="txt">Business Modeling</span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-9' ? 'active-btn' : ''}`}
                      data-tab="#tab-9"
                      onClick={() => handleTabClick('tab-9')}
                    >
                      <span className="icon-bg pe-7s-science" />
                      <span className="icon pe-7s-science" />
                      <span className="txt">CFO Services</span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-10' ? 'active-btn' : ''}`}
                      data-tab="#tab-10"
                      onClick={() => handleTabClick('tab-10')}
                    >
                      <span className="icon-bg pe-7s-disk" />
                      <span className="icon pe-7s-disk" />
                      <span className="txt">Transfer Pricing </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-11' ? 'active-btn' : ''}`}
                      data-tab="#tab-11"
                      onClick={() => handleTabClick('tab-11')}
                    >
                      <span className="icon-bg pe-7s-joy" />
                      <span className="icon pe-7s-joy" />
                      <span className="txt">Startup Advisory </span>
                    </div>
                  </div>
                  <div className='col-6 col-lg-2 col-md-4 col-md-4 col-sm-6'>
                    <div
                      className={`btn-inner tab-btn ${activeTab === 'tab-12' ? 'active-btn' : ''}`}
                      data-tab="#tab-12"
                      onClick={() => handleTabClick('tab-12')}
                    >
                      <span className="icon-bg pe-7s-portfolio" />
                      <span className="icon pe-7s-portfolio" />
                      <span className="txt">Forex Management </span>
                    </div>
                  </div>
                {/* </OwlCarousel> */}
              </div>
            </div>
            <div className="tabs-content container">
              <div className={`tab ${activeTab === 'tab-1' ? 'active-tab' : ''}`} id="tab-1">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/manager-woman-using-laptop-tablet-same-time-working-financial-reports_482257-7704.jpg?t=st=1712754713~exp=1712758313~hmac=300cb796b8decfb143b9a32f49ee36af0acf9433465de7487a4c336ee0a0919f&w=826" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Bookkeeping Services </h2>
                          </div>
                          <div className="text">
                            Transaction recording: Capturing daily transactions so you don't have to sweat the small stuff<br />
                            General ledger maintenance: Keeping the books squeaky clean and audit-ready <br /> Bank reconciliations :Aligning your records with bank statements, so you avoid surprises <br /> Monthly reporting : Summarizing key activities into user-friendly reports for insights
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-2' ? 'active-tab' : ''}`} id="tab-2">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/premium-photo/young-businesswoman-planning-project_220770-7394.jpg?w=360" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Payroll Services </h2>
                          </div>
                          <div className="text">
                            Payroll processing: Accurately calculating pay amounts and deductions even for complex situations <br />
                            Tax calculations: Determining payroll tax liabilities to minimize costly errors and penalties <br />
                            Payslip issuance: Flawlessly distributing pay slips to delight your employees <br />
                            Payroll tax filing: Timely submitting returns and payments to HMRC to avoid painful non-compliance <br />
                            Compliance management: Proactively staying updated on evolving payroll regulations so you can sleep easy
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-3' ? 'active-tab' : ''}`} id="tab-3">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/business-man-working-late_23-2148991293.jpg?t=st=1712754658~exp=1712758258~hmac=3c4ae04a28f5a14215a3680be6ba9b8cb024906cc052d80d585dac0c64a912bb&w=826" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Accounting Services </h2>
                          </div>
                          <div className="text">
                            Journal entries: Accurately recording non-cash transactions to provide a complete financial picture <br />
                            Financial statement preparation: Skillfully drafting BS, P&L, CF statements that provide true insight <br />
                            Management reporting: Preparing analytics and insights tailored to decision-making needs <br />
                            Forecasting and budgeting: Projecting future financials based on historicals and growth plans <br />
                            KPI monitoring: Tracking key accounting metrics to identify performance issues and opportunities
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-4' ? 'active-tab' : ''}`} id="tab-4">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/premium-photo/concept-taxes-paid-by-individualstax-with-person-using-laptop-desk_44277-23862.jpg?w=826" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">UX/UI Design</div>
                            <h2>Tax Services </h2>
                          </div>
                          <div className="text">
                            Tax planning: Applying our know-how to legally minimize tax liability <br />
                            Tax computation: Correctly calculating tax owed to avoid penalties <br /> Return filing: Efficiently preparing and submitting returns to meet deadlines <br /> HMRC communication: Tactfully interacting with tax authorities <br /> Advisory on direct and indirect taxes : Providing guidance on complex tax codes and filings
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-5' ? 'active-tab' : ''}`} id="tab-5">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/premium-photo/businessman-working-with-big-screens-dark-room_474601-146.jpg?w=826" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Audit Support.</h2>
                          </div>
                          <div className="text">
                            Gathering paperwork: Diligently compiling supporting documents needed to satisfy auditors <br /> Invoice pulling: Swiftly extracting invoice data required for audit sampling <br /> Responding to queries: Promptly answering auditor information requests <br /> Documentation maintenance: Meticulously organizing records so audits go smoothly
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-6' ? 'active-tab' : ''}`} id="tab-6">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/workaholic-african-american-leader-standing-front-presentation-monitor-explaining-marketing-pr_482257-8237.jpg?t=st=1712751878~exp=1712755478~hmac=1e21a9cdacbb0868696b388ff9762a305a6ca194b93cf367d1a63837f52b3144&w=826" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transformin</div>
                            <h2>Forecasting and Planning </h2>
                          </div>
                          <div className="text">
                            Data gathering: Identifying and compiling pertinent inputs to drive reliable models <br /> Model building: Developing customized forecasting models leveraging statistical best practices <br /> Forecasting analysis: Generating multi-scenario projections based on growth assumptions <br /> Insight reporting: Summarizing key modeled takeaways into memos tailored to decision needs
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-7' ? 'active-tab' : ''}`} id="tab-7">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/high-angle-man-working-late-night_23-2150280986.jpg?t=st=1712752070~exp=1712755670~hmac=1f620d78e37f4efd3ccf1af6411a28f5183b1829be6d71412b598d8e7d11a3ae&w=360" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Budgeting </h2>
                          </div>
                          <div className="text">
                            Budget modeling: Building flexible and collaborative models focused on business objectives <br /> Variance analysis: Determining budget deviations and thoughtfully identifying root causes <br /> Cost optimization: Pinpointing savings opportunities from labour to inventories to discretionary spend <br /> Performance reporting: Monitoring budget vs. actuals and presenting actionable insights
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-8' ? 'active-tab' : ''}`} id="tab-8">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/people-taking-part-high-protocol-event_23-2150951333.jpg?t=st=1712752269~exp=1712755869~hmac=cff27dc684a9028261ff385f3d262acaa5059f03fe79a06fbd85cad7fc5f3320&w=740" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>Business Modeling</h2>
                          </div>
                          <div className="text">
                            Model conceptualization: Determining appropriate modeling approaches that provide robust evaluation <br />
                            Data analysis: Thoroughly examining inputs and assumptions to ensure relevance and accuracy <br />
                            Model building: Constructing customized models leveraging statistical and analytical best practices <br />
                            Insight reportingL: Summarizing model outputs into memos outlining data-driven pros and cons for key decisions
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-9' ? 'active-tab' : ''}`} id="tab-9">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/business-person-futuristic-business-environment_23-2150970208.jpg?t=st=1712752489~exp=1712756089~hmac=47d932ca4dae03d3a8797a85453a215fd0b96b289c14038e73e60bfe54bfb679&w=740" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transforming</div>
                            <h2>CFO Services </h2>
                          </div>
                          <div className="text">
                            Strategic planning: Partnering to craft financial strategies aligned to long-term goals <br /> Financial analysis: Studying performance trends and benchmarks to identify opportunities and issues <br /> Management reporting: Summarizing insights into user-friendly dashboards and reports to drive decisions <br /> Growth strategy: Formulating data-driven growth roadmap focused on profitability and scalability
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-10' ? 'active-tab' : ''}`} id="tab-10">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-vector/flat-design-reseller-illustration_23-2149483648.jpg?w=740&t=st=1712752824~exp=1712753424~hmac=e068e97928bb796f38f8506fc1075aea1c8a9f0eef06d80c979bdcad683a57e3" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transformation</div>
                            <h2>Transfer Pricing</h2>
                          </div>
                          <div className="text">
                            Documentation maintenance: Keeping policies and supporting materials current and audit-ready <br /> Compliance management: Closely monitoring filings and obligations across jurisdictions to avoid penalties <br /> Policy optimization: Improving pricing approach by analyzing profitability impacts and industry norms <br /> Return filing: Efficiently handling return prep and submission across jurisdictions
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-11' ? 'active-tab' : ''}`} id="tab-11">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="https://img.freepik.com/free-photo/business-entrepreneur-man-presenting-company-statistics-using-tablet-financial-presentation_482257-4608.jpg?t=st=1712752783~exp=1712756383~hmac=f8773ae6e66a7d253cf15cc835120e0a5aa153a3e344b1e2728e61ca8d15d152&w=360" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Transformation</div>
                            <h2>Startup Advisory </h2>
                          </div>
                          <div className="text">
                            Pitch deck creation: Developing slick, investor-oriented presentations to highlight potential <br />
                            Financial modeling: Building comprehensive models to credibly quantify valuation and returns <br />
                            Accounting setup: Establishing scalable books and processes tailored to the venture's needs <br />
                            Investor reporting: Providing meticulous reports to update investors and support capital requests
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`tab ${activeTab === 'tab-12' ? 'active-tab' : ''}`} id="tab-12">
                <div className="tab-inner">
                  <div className="featured-service">
                    <div className="row clearfix align-items-center">
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="image-box">
                            <img src="assets/images/resource/featured-image-16.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="sec-title left-aligned">
                            <div className="sub-title">Forex Management</div>
                            <h2>diverse range of kind work.</h2>
                          </div>
                          <div className="text">
                            Exposure analysis: Determining forex risk related to cross-border transactions and flows <br />
                            Hedging execution: Implementing tailored risk management strategies aligned to risk tolerance <br />
                            Rate tracking: Monitoring exchange rate fluctuations to identify impacts and opportunities <br />
                            Treasury operations: Managing forex transactions while ensuring compliance and controls
                          </div>
                          <div className="link-box clearfix">
                            <a href="portfolio-single.html" className="theme-btn btn-style-one">
                              <span className="/contact">contact us</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     

      <Footer />
    </>
  )
}

export default Service
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Function to toggle the mobile menu visibility
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
      setIsOpen(!isOpen);
    };
    const closeNavbar = () => {
        setIsOpen(false);
    };
    return (
        <>
          {/* <header className="main-header header-style-one">
                <nav className="navbar">
                    <div className="navbar-brand">Your Brand</div>
                    <button className="navbar-toggle" onClick={toggleNavbar}>
                        Menu
                    </button>
                    <ul className={isOpen ? 'navbar-menu active' : 'navbar-menu'}>
                        <li className="navbar-item">Home</li>
                        <li className="navbar-item">About</li>
                        <li className="navbar-item">Services</li>
                        <li className="navbar-item">Contact</li>
                    </ul>
                </nav>  
            </header> */}
            
        <header className="main-header header-style-one">
            <div className="container">
                <nav className="navbar">
                    <div className="navbar-brand">
                        <div className="logo-box">
                            <div className="logo">
                                <Link to="/" title="Callix - Portfolio and Digital Agency HTML Template">
                                    <img src="assets\images\icons\ag-icon.png" alt="Callix - Portfolio and Digital Agency HTML Template" title="Callix - Portfolio and Digital Agency HTML Template" style={{ width: 120 }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {isOpen ? (
                        <button className="close-button" onClick={closeNavbar}>
                            <span className="icon flaticon-close"></span>
                        </button>
                    ) : (
                        <button className="navbar-toggle" onClick={toggleNavbar}>
                            <span className="icon flaticon-menu"></span>
                        </button>
                    )}
                    <ul className={isOpen ? 'navbar-menu active' : 'navbar-menu'}>
                        <Link to="/" className="navbar-item" onClick={closeNavbar}>Home</Link>
                        <Link to="/about" className="navbar-item" onClick={closeNavbar}>About</Link>
                        <Link to="/service" className="navbar-item" onClick={closeNavbar}>Services</Link>
                        <Link to="/blog" className="navbar-item" onClick={closeNavbar}>Blog</Link>
                        <Link to="/contact" className="navbar-item" onClick={closeNavbar}>Contact</Link>
                    </ul>
                </nav>  
            </div>
        </header>
    </>
    )
}

export default Navbar
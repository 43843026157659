import React from 'react'
import './Detail.css'
import Navbar from './Nabvar/Navbar'
import Footer from './Footer/Footer'

const Detail = () => {
    return (
        <>
            <Navbar />
            {/* Banner Section */}
            <section className="page-banner">
                <div
                    className="image-layer"
                    style={{ backgroundImage: "url(https://img.freepik.com/premium-photo/investment-analysis-amidst-city-lights-dusk_254869-2759.jpg?w=826)" }}
                />
                <div className="banner-inner">
                    <div className="faded-text light">
                        {/* <div className="f-text">
                            <span>Details</span>
                        </div> */}
                    </div>
                    <div className="auto-container">
                        <div className="inner-container clearfix">
                            <h1>Blog Details</h1>
                            <div className="page-nav">
                                <ul className="bread-crumb clearfix">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="#">Details</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section */}

            <section className='py-5'>
                {/* <div className="sec-title centered">
                    <div className="sub-title" style={{ color: "#147fd4" }}>Details</div>
                    <h2 style={{ textTransform: "capitalize", color: "#234a96" }}>Blog Details</h2>
                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card bg-light-1 shadow">
                                <img className="card-img-top" src="https://html.themexriver.com/finano/images/resource/news-18.jpg" alt="Card image cap" />
                                <div className="row first p-4">
                                    <div className="col-md-3">
                                        <p><i className="bi bi-calendar-check icn"></i> July 25, 2019</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p><i className="bi bi-person-fill icn"></i>By Admin</p>
                                    </div>
                                    <div className="col-md-3">
                                        <i className="bi bi-list-task icn"></i>Uncategorized
                                    </div>
                                    <div className="col-md-3"><i className="bi bi-chat-quote-fill icn"></i>(3) Comment</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className='ht px-3'>Aliquam augue eros, for pulvinar et rutrum non.</h3>
                                        <p className='txts px-3'>The man, who is in a stable condition in hospital, has "potentially life-changing injuries" after the overnight attack in Garvagh, County Londonderry. He was shot in the arms and legs."What sort of men would think it is accepttable to subject a young girl to this level of brutality and violence?</p>
                                        <p className='txts px-3'>"Every child has the right to feel safe and protected in their own home - how is this poor child going to sleep tonight or in coming nights? What are the long term effects on her going to be?"</p>
                                        <p className='txts px-3'>"It's quite obvious the hypocrites who carried out this dreadful attack don't care at all about the children in their community. I wonder how they would feel if their own child witnessed such a level of violence?</p>
                                        <p className='txts px-3'>"There is absolutely no justification for an attack like this in our communities and we must all work together to bring those responsible to justice and to stop this from happening to another child."</p>
                                        <p className='txts px-3'>Earlier this month, <a href="" className='text-dark'>the PSNI launched a hard-hitting advertisement campaign</a> aimed at changing public attitudes to paramilitary attacks.
                                        </p>
                                    </div>
                                </div>
                                <div className="row p-4">
                                    <div className="col-md-6">
                                        <img src="https://html.themexriver.com/finano/images/resource/news-16.jpg" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <img src="https://html.themexriver.com/finano/images/resource/news-17.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <div className="col-md-12">
                                        <h5 className='ht px-3'>A Kentucky woman who was accused last year.</h5>
                                        <p className='txts px-3'>The intruders chased the girl in the house and threatened her when she hid from them, according to the PSNI Limavady Facebook page.</p>
                                        <p className='txts px-3'>"She came out petrified with her Piggy Bank, HER PIGGY BANK! hoping that the men would take it and leave her dad alone," one outraged officer wrote.</p>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-md-12">
                                        <blockquote className='px-4'>
                                            <div className="quote-icon flaticon-left-quote"></div>
                                            <div className="txts">What sort of men would think it is acceptable to subject a young girl to this level of brutality and violence? an attack like this in ourcommunities and we must all work together.</div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Jagriti Start */}
                        <div className="col-md-4">
                            <form className="d-flex">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-primary" type="submit"><i class="bi bi-search"></i></button>
                            </form>
                            <div className="row pt-4">
                                <div className="col-md-12">
                                    <h4 className='cate text-dark'>gories</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='lnes'>Consulting <span>(3)</span></p>
                                    <p className='lnes'>Technology <span>(4)</span></p>
                                    <p className='lnes'>Life Style &nbsp;&nbsp; &nbsp; <span>(8)</span></p>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-md-12">
                                    <h4 className='text-dark rec'>ent News</h4>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-md-12 pb-4 ">
                                    <div class="media lnes">
                                        <img class="mr-3" src="https://html.themexriver.com/finano/images/resource/post-thumb-1.jpg" alt="Generic placeholder image" className='rounded-circle' />
                                        <div class="media-body pl-2">
                                            <h5 class="mt-0">Business Structured Nontp Frank Team</h5>
                                            <i className="bi bi-calendar-check"></i>July 25, 2019
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 pb-4">
                                    <div class="media lnes">
                                        <img class="mr-3" src="https://html.themexriver.com/finano/images/resource/post-thumb-2.jpg" alt="Generic placeholder image" className='rounded-circle' />
                                        <div class="media-body pl-2">
                                            <h5 class="mt-0">Business Structured Nontp Frank Team</h5>
                                            <i className="bi bi-calendar-check"></i>July 25, 2019
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 py-2">
                                    <div class="media lnes">
                                        <img class="mr-3" src="https://html.themexriver.com/finano/images/resource/post-thumb-3.jpg" alt="Generic placeholder image" className='rounded-circle' />
                                        <div class="media-body pl-2">
                                            <h5 class="mt-0">Business Structured Nontp Frank Team</h5>
                                            <i className="bi bi-calendar-check"></i>July 25, 2019
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row py-4">
                                <div className="col-md-12">
                                    <h4 className='arch text-dark'>ives</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='lnes'>January &nbsp;&nbsp;<span>(3)</span></p>
                                    <p className='lnes'>February &nbsp;&nbsp;<span>(2)</span></p>
                                    <p className='lnes'>May 2019 <span>(6)</span></p>
                                </div>
                            </div> */}
                            <div className="row py-4">
                                <div className="col-md-12">
                                    <h4 className='gal text-dark'>lery</h4>
                                </div>
                                <div className="row py-4">
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-1.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-2.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-3.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-1.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-2.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                    <div className="col-md-4">
                                        <img src="https://html.themexriver.com/finano/images/gallery/instagram-3.jpg" alt="" style={{ width: 100 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default Detail
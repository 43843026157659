import React from 'react'
import Home from './component/Home'
import About from './component/About'
import Service from './component/Service'
import Blog from './component/Blog'
import Contact from './component/Contact'
import ContactUs from './component/ContactUs'
import Detail from './component/Detail'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


const App = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />    
      <Route path="/about" element={<About />} />
      <Route path="/service" element={< Service/>} />
      <Route path="/blog" element={< Blog/>} />
      <Route path="/contact" element={< Contact/>} />
      <Route path="/detail" element={< Detail/>} />
      <Route path="/contactus" element={< ContactUs/>} />
    </Routes>
  </Router>
  )
}

export default App
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer/Footer';
import Navbar from './Nabvar/Navbar';

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);

  return (
    <>
      <Navbar />
      {/* Hidden Navigation Bar */}
      <section className="hidden-bar right-align">
        <div className="hidden-bar-closer">
          <button>
            <span className="flaticon-letter-x" />
          </button>
        </div>
        {/* Hidden Bar Wrapper */}
        <div className="hidden-bar-wrapper">
          <div className="logo">
            <a href="index.html">
              <img src="images/logo.png" alt="" />
            </a>
          </div>
          <div className="content-box">
            <h3>Get In Touch</h3>
            <div className="text">
              We must explain to you how all seds this mistakens idea off denouncing
              pleasures and praising pain was born and I will give you a completed
              accounts..
            </div>
            <a href="about.html" className="theme-btn btn-style-two">
              <div className="btn-title">Consultation</div>
            </a>
          </div>
          <div className="contact-info">
            <h2>Contact Info</h2>
            <ul className="list-style-two">
              <li>
                <span className="icon fa fa-map-marker-alt" />
                Sailing 26, Old Brozon Mall, Newyrok NY 10005
              </li>
              <li>
                <span className="icon fa fa-phone-alt" />
                <a href="tel:555626-0234">555 626-0234</a>
              </li>
              <li>
                <span className="icon fa fa-envelope-open" />{" "}
                <a href="mailto:support@sailing.com">support@sailing.com</a>
              </li>
              <li>
                <span className="icon fa fa-clock" />
                Week Days: 09.00 to 18.00 Sunday: Closed
              </li>
            </ul>
          </div>
        </div>
        {/* / Hidden Bar Wrapper */}
      </section>
      {/* End / Hidden Bar */}
      {/* Banner Section */}
      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/banner-image-1.jpg)" }}
        />
        <div className="banner-inner">
          <div className="faded-text light">
            <div className="f-text">
              <span>About Us</span>
            </div>
          </div>
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>About us</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">About</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Welcome Section*/}
      <section className="welcome-section pt-5 pb-5">
        <div className="container">
          <div className="content">
            <h2 className="pb-4 text-center aos-init aos-animate" data-aos="flip-left">The accountants of gods</h2>
            <div className="row">
              <div className="col-md-6" data-aos="flip-right">
                <div className="lower-text">
                  <ul className='' style={{ textAlign: "justify" }}>
                    <li>Agkika spelt as (A as in America, Key, Ca as in Car) is a sanskarit word that means accountant of Gods.</li>
                    <li>Agkika is a disruptive startup, a center of excellence, bringing in out of box approach and introducing intelligent delivery model to the Outsourcing world.</li>
                    <li>Agkika is to the professionals what the professionals are or aspire to be to their clients; A comprehensive service provider and consultant in all matters financial and strategy, a trusted partner in their path to progress.</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6" data-aos="flip-left">
                <img src="assets\images\about-.jpeg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Banner Section */}
      <section className="featured-services pt-5 pb-5">
        <div className="sec-title-two">
          <h2 className="text-center" style={{ color: "#234a96" }} data-aos="flip-right">What Do We Do</h2>
        </div>
        <div className="auto-container">
          <div className="carousel-box">
            <div className="featured-service-carousel owl-theme owl-carousel owl-loaded owl-drag">
              <div className="featured-service-block">
                <div className="inner-box">
                  <div className="row clearfix">
                    {/* Image Column */}
                    <div className="image-column col-lg-6 col-md-12 col-sm-12" data-aos="fade-down">
                      <div className="inner">
                        <div className="image-box">
                          <img
                            src="assets/images/aboutUs.jpeg"
                            alt=""
                          />
                        </div>
                        <div
                          className="image-layer"
                          style={{
                            backgroundImage:
                              "url('assets/images/aboutUs.jpg')"
                          }}
                        />
                      </div>
                    </div>
                    {/* Text Column */}
                    <div className="text-column col-lg-6 col-md-12 col-sm-12" data-aos="fade-up">
                      <div className="inner">
                        <div className="lower-text">
                          <p>
                            Our <strong> Intelligent Delivery Model</strong> provides disruptive finance, accounting, payroll, and compliance solutions. And we're just getting started.
                          </p>
                          <p>Our <strong>niche strategic offerings</strong> let clients adopt revenue-boosting business models without extra overheads. With our expertise in transfer pricing, tax planning, forecasting, and more, partners can cater to new audiences and scale seamlessly.</p>
                          <p>Our services are a <strong>plug-and-play solution</strong> for accountants and finance professionals across the UK. We are your on-demand team - finance experts at your fingertips.</p>
                          <p>Trust us, we know what it takes to build a business. Headquartered in London but delivering globally, we combine bold startup passion with decades of experience.</p>
                          <p>Looking for fresh ideas in business process services? Let's shake things up. At Agkika, we tackle the details - with humor and a refusal to accept the status quo. It's time for a new era in outsourcing.</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>

  )
}

export default About
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from './Nabvar/Navbar' // Corrected import path
import Footer from './Footer/Footer'
//import ContactUs from './ContactUs'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_md54fts', 'template_n6e4egu', form.current, {
        publicKey: 'qDoZp6n8KbWlRRK-E',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <>
      <Navbar />
      {/* Banner Section */}
      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/banner-image-1.jpg)" }}
        />
        <div className="banner-inner">
          <div className="faded-text light">
            <div className="f-text">
              <span>contact Us</span>
            </div>
          </div>
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>contact us</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Banner Section */}
      <section className="contact pt-5 pb-5">
        <div className="container">
          <div className="sec-title left-aligned">
            <div className="sub-title">connect with us</div>
            <h2>Get in Touch</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <form ref={form} onSubmit={sendEmail} className='validate' method="post" action="/">
                <div className="frm-grup field-inner">
                  <input type="text" className="form-control frm" aria-describedby="textHelp" placeholder="Enter Your Name" name="user_name" required />
                </div>
                <div className="frm-grup field-inner">
                  <input placeholder="Your Phone Number" className='form-control frm' type="tel" tabindex="3" name="user_number" required></input>
                </div>
                <div className="frm-grup field-inner">
                  <input placeholder="Your Email Address" className='form-control frm' type="email" name="user_email" tabindex="2" required />
                </div>
                <div className="frm-grup">
                  <textarea className="form-control frm" id="exampleFormControlTextarea1" rows="3" placeholder="Type your message" name="message" required />
                </div>
                {/* <button type="submit" className="btn btn-primary">Submit Now</button> */}
                <input class="btn btn-primary" type="submit" value="Send" />
              </form>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.263934846979!2d-0.09080452393740908!3d51.526718709292545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca60b8446f9%3A0xa767f71cf0ba1bd4!2s124%2C%20128%20City%20Rd%2C%20London%20EC1V%202NJ%2C%20UK!5e0!3m2!1sen!2sin!4v1712657018623!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>



            </div>
          </div>
        </div>
      </section>
      {/* Banner Section */}
      {/*Call To Section*/}
      <section className="call-to-section">
        <div className="bubble-dotted">
          {/* Bubble Dotted Content */}
        </div>
        <div className="auto-container">
          <div className="content">
            <div className="sub-title">Accounting outsourcing</div>
            <h2>Financial solutions tailored to your business needs.</h2>
          </div>
        </div>
      </section>

      {/* <ContactUs /> */}


      <Footer />
    </>
  );
};

export default Contact;

import React, { useEffect} from 'react';
import './Blog.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './Nabvar/Navbar'
import Footer from './Footer/Footer'

const Blog = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);
  return (
    <>
      <Navbar />
      {/* Banner Section */}
      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/banner-image-1.jpg)" }}
        />
        <div className="banner-inner">
          <div className="faded-text light">
            <div className="f-text">
              <span>Blog</span>
            </div>
          </div>
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>Blog</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Blog</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section */}

      <section className='pt-5 pb-5 sctn'>
        <div className="sec-title-three h2">
          <div className="sec-title centered">
            <div className="sub-title" style={{ color: "#147fd4" }}>BLOG LIST</div>
            <h2 style={{ textTransform: "capitalize", color: "#234a96" }} data-aos="zoom-in">We are here to manage your finance <br /> with experience</h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card" data-aos="fade-right">
                <img src="https://html.themexriver.com/finano/images/resource/news-7.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" data-aos="fade-down">
                <img src="https://html.themexriver.com/finano/images/resource/news-8.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" data-aos="fade-left">
                <img src="https://html.themexriver.com/finano/images/resource/news-9.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-4">
              <div className="card" data-aos="fade-left">
                <img src="https://html.themexriver.com/finano/images/resource/news-10.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" data-aos="fade-up">                
                <img src="https://html.themexriver.com/finano/images/resource/news-11.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" data-aos="fade-right">
                <img src="https://html.themexriver.com/finano/images/resource/news-12.jpg" className="card-img-top" alt="..." />
                <div className="card-body lower-content">
                  <div className="row">
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-calendar-check icn"></i> September 12, 2019</p>
                    <p className='pl-3' style={{ color: "#012068" }}><i className="bi bi-person-fill icn"></i>Admin</p>
                  </div>
                  <p className="card-text">What we are capable of usually gets discovered.</p>
                  <a href="/detail" className="btn btn-primary btns">Go somewhere</a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="news-block-three col-lg-4 col-md-6 col-sm-12">
            <div
              className="inner-box wow fadeInLeft animated"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
              style={{
                visibility: "visible",
                animationDuration: "1500ms",
                animationDelay: "0ms",
                animationName: "fadeInLeft"
              }}
            >
              <div className="lower-content">
                <h4>
                  <a href="blog-single.html">
                    Aliquam augue eros, for pulvinar et rutrum non.
                  </a>
                </h4>
              </div>
            </div>
          </div> */}

        </div>
      </section>
      <Footer />
    </>
  );
}

export default Blog;

import React, { useState } from 'react';

const Tab = () => {
    const [activeTab, setActiveTab] = useState('tab-1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <>
            {/*Services Section*/}
            <section className="services-section-four">
                {/* <div className="bubble-dotted">
          <span className="dotted dotted-1" />
          <span className="dotted dotted-2" />
          <span className="dotted dotted-3" />
          <span className="dotted dotted-4" />
          <span className="dotted dotted-5" />
          <span className="dotted dotted-6" />
          <span className="dotted dotted-7" />
          <span className="dotted dotted-8" />
          <span className="dotted dotted-9" />
          <span className="dotted dotted-10" />
        </div> */}
                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="sub-title">Hiring</div>
                        <h2>What you get by hiring Agkika?</h2>
                    </div>
                    <div className="tabs-box services-tabs">
                        <div className="tab-buttons">
                            <div className="row clearfix">
                                {/* <OwlCarousel className="owl-theme" {...options}> */}
                                <div className='col-6 col-lg-6 col-md-6 col-md-6 col-sm-6'>
                                    <div
                                        className={`btn-inner tab-btn ${activeTab === 'tab-1' ? 'active-btn' : ''}`}
                                        data-tab="#tab-1"
                                        onClick={() => handleTabClick('tab-1')}
                                    >
                                        <span className="icon-bg pe-7s-target" />
                                        <span className="icon pe-7s-target" />
                                        <span className="txt">As your accounting outsourcing partner</span>
                                    </div>
                                </div>
                                <div className='col-6 col-lg-6 col-md-6 col-md-6 col-sm-6'>
                                    <div
                                        className={`btn-inner tab-btn ${activeTab === 'tab-2' ? 'active-btn' : ''}`}
                                        data-tab="#tab-2"
                                        onClick={() => handleTabClick('tab-2')}
                                    >
                                        <span className="icon-bg pe-7s-star" />
                                        <span className="icon pe-7s-star" />
                                        <span className="txt">As your financial services partner</span>
                                    </div>
                                </div>
                                {/* </OwlCarousel> */}
                            </div>
                        </div>
                        <div className="tabs-content container">
                            <div className={`tab ${activeTab === 'tab-1' ? 'active-tab' : ''}`} id="tab-1">
                                <div className="tab-inner">
                                    <div className="featured-service">
                                        <div className="row clearfix align-items-center">
                                            <div className="col-md-4">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Bookkeeping Services</h3>
                                                    <p class="description">Let us handle the books; you handle the breakthroughs.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Payroll Services</h3>
                                                    <p class="description">Perfect your payroll services, to project peace of mind.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox green">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">VAT Return FIling</h3>
                                                    <p class="description">We file and finesse your taxes, so you stress less.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix align-items-center pt-3">
                                            <div className="col-md-4">
                                                <div class="serviceBox green">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Year-end Accounts Finalization </h3>
                                                    {/* <p class="description">Transfer pricing fine-tuned, global compliance achieved.</p> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Corporation Tax Filing </h3>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Self-assessment Preparation</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix align-items-center pt-3">
                                            <div className="col-md-4">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Audit Support</h3>
                                                    <p class="description">Support through audits, comfort through credits.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Management Accounts</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div class="serviceBox green">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Corporation Tax FilinDebtor</h3>
                                                    <p class="description">Streamline receivables, optimize payables, enhance cash flow.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab ${activeTab === 'tab-2' ? 'active-tab' : ''}`} id="tab-2">
                                <div className="tab-inner">
                                    <div className="featured-service">
                                        <div className="row clearfix align-items-center">
                                            <div className="col-md-3">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Forecasting and Planning</h3>
                                                    <p class="description">Forecast today, lead tomorrow.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Budgeting</h3>
                                                    <p class="description">Budget with foresight, spend with insight.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox green">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Business Modeling</h3>
                                                    <p class="description">Build models to shape your market mold.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">CFO Services</h3>
                                                    <p class="description">Strategic CFO insights, financial foresight delivered.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix align-items-center pt-3">
                                            <div className="col-md-3">
                                                <div class="serviceBox green">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Transfer Pricing</h3>
                                                    <p class="description">Transfer pricing fine-tuned, global compliance achieved.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Startup Advisory</h3>
                                                    <p class="description">Startups steered right from the start.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox blue">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-rocket"></i></span>
                                                    </div>
                                                    <h3 class="title">Forex Management</h3>
                                                    <p class="description">Manage forex, master your margins.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="serviceBox">
                                                    <div class="service-icon">
                                                        <span><i class="fa fa-globe"></i></span>
                                                    </div>
                                                    <h3 class="title">Treasury Management</h3>
                                                    <p class="description">Build models to shape your market mold.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tab
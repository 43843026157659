import React from 'react'
import './Home.css'

const HomeContact = () => {
    return (
        <div className='imgg'>
            <section className='secti'>
                <div className="section-header sec">
                    <div className="container con">
                        <div class="sec-title style-three light centered"><div class="sub-title">contact</div>
                            <h2 data-aos="flip-left">Get In Touch</h2>
                        </div>
                        {/* <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever
                            since the 1500s, when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                        </p> */}
                    </div>
                </div>
                <div className="container con">
                    <div className="row rr">
                        <div className="contact-info" data-aos="fade-down">
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-home" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Address</h4>
                                    <p>
                                        124-128 City Road, EC1V 2NX, London, England GB
                                    </p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-phone" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Phone</h4>
                                    <p>+ 44-7776-681468</p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-envelope" />
                                </div>
                                <div className="contact-info-content">
                                    <h4>Email</h4>
                                    <p>info@agkika.uk</p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form" data-aos="fade-up">
                            <form action="" id="contact-form">
                                <h2>Send Message</h2>
                                <div className="input-box">
                                    <input type="text" required="true" name="" />
                                    <span>Full Name</span>
                                </div>
                                <div className="input-box">
                                    <input type="email" required="true" name="" />
                                    <span>Email</span>
                                </div>
                                <div className="input-box">
                                    <input type="tel" required="true" name="" />
                                    <span>Phone No</span>
                                </div>
                                <div className="input-box">
                                    <textarea required="true" name="" defaultValue={""} />
                                    <span>Type your Message...</span>
                                </div>
                                <div className="input-box">
                                    <input type="submit" defaultValue="Send" name="" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomeContact